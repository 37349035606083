import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as sectionsActions from '../../redux/actions/sections';
import * as categoryActions from '../../redux/actions/sb-category';
import * as questionnairesActions from '../../redux/actions/questionnaires';

import UnitAccordion from '../../components/skill-builders/UnitAccordion';
import Loading from '../../components/common/Loading';

class SkillBuildersList extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skillBuilders: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    skillbuilderCategory: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      skillBuilders: [
        ...this.props.skillBuilders.data
      ],
      skillBuildersLookupTable: []
    };

    this.onSearch = this.onSearch.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
  }

  componentWillMount(){
    this.props.actions.fetchSBcategory();
    if(this.props.isAuthenticated)
      this.props.actions.questionnairesRequest();
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props.skillbuilderCategory;
    const { isRequesting, data: nextCat } = nextProps.skillbuilderCategory;

    if(!isRequesting && data !== nextCat){
      const categoryArray = nextCat.map((cat) => {
        let data = {
          id: cat.id,
          name: cat.title,
          icon: '',
          open: false,
        };
        switch (cat.description) {
        case '1':
          data.icon = require('../../assets/images/sb-job-seeking.png');
          break;
        case '2':
          data.icon = require('../../assets/images/sb-self-management.png');
          break;
        case '3':
          data.icon = require('../../assets/images/sb-communication.png');
          break;
        case '5':
          data.icon = require('../../assets/images/sb-analytical.png');
          break;
        case '6':
          data.icon = require('../../assets/images/sb-planning.png');
          break;
        case '4':
          data.icon = require('../../assets/images/sb-people.png');
          break;
        }

        return data;
      });

      this.setState({
        skillBuildersLookupTable: [...categoryArray]
      });
    }
  }

  handleCollapse(category){
    const { skillBuildersLookupTable }= this.state;

    return ()=>{
      const resolveTable = skillBuildersLookupTable.map((item)=>{
        if (item.id === category){
          const obj = { ...item, open: !item.open};
          return obj;
        }
        return item;
      });

      this.setState({ skillBuildersLookupTable: resolveTable});
    };
  }

  onSearch(event) {
    const { skillBuilders } = this.props;
    const value = event.target.value;

    if (value.length === 0)
      return this.setState({ skillBuilders: skillBuilders.data });

    const units = skillBuilders.data.filter(unit => unit.title.includes(value));

    this.setState({ skillBuilders: units });
  }

  render() {
    const { skillBuildersLookupTable } = this.state;
    const { skillBuilders, skillbuilderCategory } = this.props;
    const { isRequesting: isRequestingSBCategory } = skillbuilderCategory;
    const { isRequesting, data: skillBuildersData } = skillBuilders;

    const content = isRequesting || isRequestingSBCategory ?
      (<div className="loading-container">
        <Loading/>
      </div>):
      (<div style={{marginTop:30}}>
        <UnitAccordion
          auth
          skillbuilders={skillBuildersData}
          handleCollapse={this.handleCollapse}
          skillBuildersLookupTable={skillBuildersLookupTable}/>
      </div>);

    return (
      <div>
        <Helmet title="Skill Builders"/>

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    skillbuilderCategory: state.skillbuilderCategory,
    skillBuilders: state.skillBuilders,
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.profile.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, sectionsActions, categoryActions, questionnairesActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillBuildersList);
