import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import CloseIcon from '../../assets/images/close.svg';

const DashboardAlert = ({ profile, onDismissBanner }) => {
  const isRegularUser = profile.role === 'user';
  const isIndustryMentor = profile.role === 'industry-representative';
  const isTalentDevelopers = (
    profile.role === 'institution-admin' ||
      profile.role === 'group-admin' ||
      profile.role === 'educator'
  );

  const adminPanelLink = profile.role === 'educator' ?
    '/educator/groups' :
    '/organization/admin';

  return (
    <Row>
      <Col>
        <div className="dashboard-alert">
          <div className="dashboard-alert-header">
            <h3>Welcome to your dashboard!</h3>
            <div className="dashboard-close" onClick={onDismissBanner}>
              <img src={CloseIcon} />
            </div>
          </div>

          {(isRegularUser) &&
            <p>Take charge of your career and your future with undeniable evidence of your skills and potential. Our tools help you build, capture, and showcase proof of your skills, no matter how or where you got them. Ensure you're always ready to stand out from competition based on concrete evidence of your abilities to get the career you want.</p>}

          {isTalentDevelopers &&
            <p>Review the quick links below to explore the CareerPrepped tools that support lifelong career success. Don't forget to review the help articles below and visit your <Link to={adminPanelLink}>admin panel</Link> where you can manage your users, access resources to maximize your use of CareerPrepped, and more!</p>}

          {(isIndustryMentor) &&
            <p>Review the quick links below to explore the CareerPrepped tools that support lifelong career success. As a CareerPrepped Industry Mentor, you'll be able to see the impact you're making with the metrics we track for you on your <Link to="/myfeedback">feedback panel</Link>. Don't forget to explore the help articles below, especially <a href="https://help.careerprepped.com/help/tips-for-giving-feedback-on-skill-evidence" target="_blank">tips on giving feedback</a>.</p>}

          <a href="#" onClick={onDismissBanner}>Dismiss for now</a>
        </div>
      </Col>
    </Row>
  );
};

DashboardAlert.propTypes = {
  profile: PropTypes.object.isRequired,
  onDismissBanner: PropTypes.func.isRequired
};

export default DashboardAlert;
