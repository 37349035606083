import initialState from './initialState';

import {
  RESUME_REQUEST,
  RESUME_SUCCESS,
  RESUME_FAILURE,
  CREATE_RESUME_REQUEST,
  CREATE_RESUME_SUCCESS,
  CREATE_RESUME_FAILURE,
  DELETE_RESUME_REQUEST,
  DELETE_RESUME_SUCCESS,
  DELETE_RESUME_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.resume, action) {
  switch (action.type) {
  case RESUME_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case RESUME_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.resume,
      isRequesting: false,
    });

  case RESUME_FAILURE:
    return Object.assign({}, state, {
      data: [],
      error: action.error,
      isRequesting: false,
    });

  case CREATE_RESUME_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case CREATE_RESUME_SUCCESS:
    return Object.assign({}, state, {
      isSubmitting: false,
    });

  case CREATE_RESUME_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: false,
      error: action.error,
    });

  case DELETE_RESUME_REQUEST:
    return Object.assign({}, state, {
      isDeleting: {
        status: true,
        id: action.id,
      },
    });

  case DELETE_RESUME_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(item => item.id !== state.isDeleting.id)],
      isDeleting: {
        status: false,
        id: null,
      },
    });

  case DELETE_RESUME_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: {
        status: false,
        id: null,
      },
    });

  default:
    return state;
  }
}
