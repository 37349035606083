import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import SkillCard from '../../components/myskills/skillCardComponents/SkillCard';
import AddYourCustomSkills from '../../components/myskills/AddYourCustomSkills';
import {
  ADD_SKILLS,
  BOTH_SKILLS,
  NEWEST,
  OLDEST,
  SKILL_A_Z,
  SKILL_Z_A,
  RATING_HIGH,
  RATING_LOW,
} from '../../constants/myskills';
import { SEARCH } from '../../utilConstants';
import { withHooks } from '../../utils/withHooks';
import { isNull, isEmpty, isUndefined } from 'lodash';
import { callApi } from '../../middlewares/api';
import Loading from '../../components/common/Loading';

class MySkills extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skillBadgeList: PropTypes.array,
    currentUser: PropTypes.object.isRequired,
    params: PropTypes.object,
    skillsTotalPage: PropTypes.number,
    badgesTotalPage: PropTypes.number,
    skillsTotalItems: PropTypes.number,
    badgesTotalItems: PropTypes.number,
    badgeLinks: PropTypes.object,
    profile: PropTypes.object,
    location: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen1: false,
      isOpen2: false,
      addYourCustomSkills: false,
      filtered: [],
      filteredBadgeList: [],
      sortParam: 'newest',
      skillSearchParam: '',
      badgeSearchParam: '',
      resourceTypeParam: 'badge',
      bothSkills: BOTH_SKILLS,
      skillToggleFilter: NEWEST,
      badgeToggleFilter: NEWEST,
      addedSkillsCount: 0,
      skillPageNo: 1,
      badgePageNo: 1,
      hasMoreSearchResults: true,
      addedBadgesCount: 0,
      requestorDetails: {},
      isMySkillsRequesting: false,
      prevSortOption: false,
      isSearchQuery: false,
      isFilterRequesting: false,
      isFilterSearching: false,
    };

    this.handleSubmitSkill = this.handleSubmitSkill.bind(this);
  }

  componentDidMount() {
    this.props.actions.getEducators();
    this.props.actions.getIndustryReps();
    this.props.actions.getPeers(this.getUserId());
    this.props.actions.getFollowers(this.getUserId());
    this.getMySkills(null, null, 1, 'skill');
    this.getMySkills(null, null, 1, 'badge');
    this.getRequestorDetails();

    if (this.props?.location?.hash === '#otherSkills') {
      this.setSkillsPillActive();
    }
    window.scrollTo(0,0);
  }

  setSkillsPillActive() {
    this.setState({
      resourceTypeParam: 'skill'
    });
  }

  getRequestorDetails() {
    const userId = this.props.params.userId;
    localStorage.setItem('mySkillsParams', JSON.stringify(this.props.location));

    if (userId && userId.length > 1) {
      callApi('', 'get', `user/user/${userId}`, true).then((response) => {
        this.setState({requestorDetails: response.data});
      });
    }
  }

  getUserId() {
    return this.props.params.userId ?? this.props.currentUser.id;
  }

  isAdmin() {
    return this.props.params.userId !== undefined;
  }

  getMySkills = async (links, filter = '&sort=newest', page = 1, resource = null, noLoadNoClear = false) => {
    const { actions } = this.props;

    // update page number in case of call for skill refresh from portfolio file
    this.setState({[`${this.state.resourceTypeParam}PageNo`]: page});

    if(!noLoadNoClear){
      this.setState({ isMySkillsRequesting: true });

      if(this.state.resourceTypeParam === 'skill'){
        actions.clearMySkills();
      } else {
        actions.clearMyBadges();
      }
    }

    let { resourceTypeParam, isSearchQuery } = this.state;
    if(resource){
      resourceTypeParam = resource;
    }

    let updatedFilter = filter;
    if(isNull(filter) || isEmpty(filter)){
      updatedFilter = this.state[`${resourceTypeParam}QueryParam`];
    }

    try {
      const skillsList = await actions.getMySkillsPage(
        this.getUserId(),
        links,
        updatedFilter,
        resourceTypeParam,
        page,
        isSearchQuery
      );

      const countPropName = `added${resourceTypeParam === 'skill' ? 'Skills': 'Badges'}Count`;
      const countPropValue = skillsList.myskills.data[`${resourceTypeParam}s`].length || 0;

      this.setState({
        isSearchQuery: false,
        [countPropName]: countPropValue
      });

      if (
        typeof(this.props.skillsTotalPage) != 'undefined' &&
          typeof(this.props.badgesTotalPage) != 'undefined'
      ) {
        setTimeout(() => {
          this.setState({
            isMySkillsRequesting: false,
            isFilterRequesting: false,
          });
        }, 1000);
      }

      return skillsList;
    } catch (e) {
      this.setState({
        isMySkillsRequesting: false,
        isFilterRequesting: false,
      });
    }
  };

  handleSubmitSkill = () => {
    const { resourceTypeParam } = this.state;
    const page = this.state[`${resourceTypeParam}PageNo`] + 1;
    const totalPage = this.props[`${resourceTypeParam}sTotalPage`];
    if(totalPage > page || totalPage === page){
      this.setState({[`${resourceTypeParam}PageNo`]: page});
      this.getMySkills(null, null, page, null, true);
      this.setState({page});
    }
  }

  searchTextHandler = (e) => {
    const { sortParam, resourceTypeParam } = this.state;
    const page = this.state[`${resourceTypeParam}PageNo`];

    let queryParam = '';

    if(sortParam){
      queryParam = `&sort=${sortParam}`;
    }

    if(resourceTypeParam){
      queryParam = `${queryParam}&resource_type=${resourceTypeParam}`;
    }

    queryParam = `${queryParam}&search=${e.target.value}`;
    this.setState({[`${resourceTypeParam}QueryParam`]: queryParam});

    this.setState({[`${resourceTypeParam}SearchParam`]: e.target.value, page});
  };

  toggleDropdown1 = () => {
    this.setState((state) => {
      return {
        isOpen1: !state.isOpen1,
      };
    });
  };

  onChangeType = (e) => {
    const label = e.target.getAttribute('data-label') || null;
    if(label === 'skill') {
      window.location.hash = '#otherSkills';
    } else {
      window.location.hash = '';
    }

    this.setState({ resourceTypeParam: label });
  };

  changeValue = (e) => {
    const label = e.target.getAttribute('data-label') || null;
    let queryParam = '';

    if ( this.state.prevSortOption === false || this.state.prevSortOption != label) {
      this.setState({prevSortOption: label, isFilterRequesting: true, isFilterSearching: true});

      const { resourceTypeParam } = this.state;
      const searchParam = this.state[`${resourceTypeParam}SearchParam`];

      this.setState({[`${resourceTypeParam}PageNo`]: 1 });
      if(resourceTypeParam === 'skill'){
        this.props.actions.clearMySkills();
      }else{
        this.props.actions.clearMyBadges();
      }

      const requestObject = {
        'newest' : { key: `${resourceTypeParam}ToggleFilter`, value: NEWEST },
        'oldest' : { key : `${resourceTypeParam}ToggleFilter`, value: OLDEST },
        'ascending': { key: `${resourceTypeParam}ToggleFilter`, value: SKILL_A_Z },
        'descending': { key: `${resourceTypeParam}ToggleFilter`, value: SKILL_Z_A },
        'average_rating_asc': { key: `${resourceTypeParam}ToggleFilter`, value: RATING_LOW },
        'average_rating_desc': { key: `${resourceTypeParam}ToggleFilter`, value: RATING_HIGH },
      };

      if(requestObject[label]?.key === `${resourceTypeParam}ToggleFilter`){
        queryParam = `${queryParam}&sort=${label}`;

        this.setState({
          sortParam: label,
        });
      }

      if(searchParam){
        queryParam = `${queryParam}&search=${searchParam}`;
      }
      this.setState({[`${resourceTypeParam}QueryParam`]: queryParam});

      this.setState({isSearchQuery: true}, () => this.getMySkills(null, queryParam, 1));

      this.setState(() => {
        const state = {};

        state[requestObject[label]?.key]= [requestObject[label]?.value];
        state['isFilterRequesting'] = true;
        state['isFilterSearching'] = true;
        return state;
      });
    }
  };

  setIsSearchQueryState = value => this.setState({isSearchQuery: value});

  getContent(skillBadgeList) {
    const params = new URLSearchParams(this.props?.location?.search);
    const institutionId = params?.get('institutionId');

    if ( skillBadgeList.length === 0 &&
      typeof(this.props.badgesTotalPage) != 'undefined' &&
      typeof(this.props.skillsTotalPage) != 'undefined' &&
      !this.state.isFilterSearching &&
      this.state.resourceTypeParam === 'skill'
    ) {
      return (<div className="text-center my-5 no-skills-added">
        <div>
          <i className="fas fa-info-circle" />
        </div>
        <p>
          While CareerPrepped has specifically defined skills represented by Skill Badges, we know you have other skills beyond the ones we’ve defined. Here, you can add any skill you have and then prove it with portfolio evidence. Any skill you add will start with a 0 of 5-star rating. Level up your skills by getting feedback so you know which are proven well and which need stronger evidence. Anyone can claim they have skill, but you’ll be able to prove it!
        </p>
      </div>);
    } else if ( skillBadgeList.length === 0 &&
      typeof(this.props.badgesTotalPage) != 'undefined' &&
      typeof(this.props.skillsTotalPage) != 'undefined' &&
      !this.state.isFilterSearching &&
      this.state.resourceTypeParam === 'badge'
    ) {
      return (<div className="text-center my-5 no-skills-added">
        <div>
          <i className="fas fa-info-circle" />
        </div>
        <p>
          You haven’t claimed Skill Badges yet. Skill Badges are digital representations of specifically defined skills backed by evidence you provide. You can review <Link to="/skill-builders">Skill Builders</Link> to learn about a skill and claim Skill Badges for the skills you feel you have and can prove with evidence. Your claimed Skill Badges will always start with a 0 of 5-star rating. Level them up by getting feedback so you know which skill claims are proven well and which need stronger evidence.
        </p>
      </div>);
    } else if(skillBadgeList.length === 0 && typeof(this.props.badgesTotalPage) != 'undefined' && typeof(this.props.skillsTotalPage) != 'undefined' && this.state.isFilterSearching){
      return (<div className="text-center my-5 skill-not-found">
        <p className="font14 text-secondary">{'Sorry, we couldn\'t find anything!'}</p>
      </div>);
    }
    return (
      <div id="scrollableDiv" style={{ height: 800}}>
        <div className="custom-search-skill">
          <InfiniteScroll
            dataLength={skillBadgeList.length}
            next={this.handleSubmitSkill}
            height={800}
            hasMore={this.state.hasMoreSearchResults}>
            <div className="SkillCard-Container">
              {skillBadgeList.map((data, index) => {
                return (<SkillCard
                  getMySkills={this.getMySkills}
                  key={index}
                  data={data}
                  isAdmin={this.isAdmin()}
                  profile={this.props.profile}
                  institutionId={institutionId}
                  setIsSearchQueryState={this.setIsSearchQueryState}/>);
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }

  toggleAddCustomSkill = () => {
    this.setState({
      addYourCustomSkills: !this.state.addYourCustomSkills,
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') return this.handleSubmitSearchSkill(event);
  }

  handleSubmitSearchSkill = () => {
    const { resourceTypeParam } = this.state;
    const queryParam = this.state[`${resourceTypeParam}QueryParam`];
    const searchText = this.state[`${resourceTypeParam}SearchParam`];
    const page = this.state[`${resourceTypeParam}PageNo`];

    if(!isEmpty(searchText) && searchText.length > 2){
      this.setState({isSearchQuery: true, isFilterSearching: true, isFilterRequesting: true}, () => this.getMySkills(null, queryParam, page));
    } else if(isEmpty(searchText)) {
      this.getMySkills(null, null, page);
      this.setState({isFilterSearching: false, isFilterRequesting: true});
    }
  }

  render() {
    const { isOpen1, resourceTypeParam, requestorDetails, isMySkillsRequesting, isFilterRequesting } = this.state;
    const { profile, skillsTotalItems, badgesTotalItems } = this.props;
    const params = new URLSearchParams(this.props?.location?.search);
    const name = params?.get('username');
    const profileDetails = isEmpty(requestorDetails) ? profile : requestorDetails;
    return (
      <div className="container pb-2">
        <div className="mySkillsContainer myFeedbackContainerStyle mySkillsContainerStyle content badges">
          {(isMySkillsRequesting && !isFilterRequesting) ? <div style={{ height: 800, paddingTop: 50}}><Loading /></div> : (<>
            <Row className="myskillAdvSearch">
              <Col md="12">
                <div className="border-bottom pb-2 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="d-flex align-items-center">
                      <Link
                        to={`/cp/${profileDetails.vanity}`}
                        title={`Link to ${profileDetails.name}’s Career Site`}>
                        {
                          profileDetails.avatar && profileDetails.avatar !== '' ?
                            <img
                              className="profile-image large"
                              src={profileDetails.avatar}
                              style={{
                                objectFit: 'cover',
                                backgroundSize: 'contain',
                              }}
                              alt={`${profileDetails.name}'s Avatar`}/>:
                            <span
                              style={{
                                lineHeight: '50px',
                                fontSize: 24
                              }}
                              className="media-object header-image large initial-avatar">
                              {profileDetails.name.slice(0,1)}
                            </span>
                        }
                      </Link>
                      <span className="ml-2">{`${name ? `${name}'s` : 'My'} Claimed Skills`}</span>
                    </h2>
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div
                  onClick={this.onChangeType}
                  data-label="badge"
                  className={`pointer customBadge ${
                    resourceTypeParam === 'badge' ? 'selected' : ''
                  }`}>
                  {`Skill Badges (${badgesTotalItems || 0})`}
                </div>
                <div
                  onClick={this.onChangeType}
                  data-label="skill"
                  className={`pointer customBadge ${
                    resourceTypeParam === 'skill' ? 'selected' : ''
                  }`}>
                  {`Other Skills (${skillsTotalItems || 0})`}
                </div>
              </Col>
              <Col md="12">
                <p>Level up any of your claimed skills by seeking Feedback and Endorsements. Click Action on any of your claimed skills to see all your available options to manage the skill.</p>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="3" className="pr-md-0">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          value={this.state[`${resourceTypeParam}SearchParam`]}
                          placeholder={SEARCH}
                          onKeyPress={this.handleKeyPress}
                          onChange={this.searchTextHandler}/>
                        <InputGroupAddon className="pointer" addonType="append" onClick={this.handleSubmitSearchSkill}>
                          <i className="fa fa-search" />
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="3" className="pr-md-0">
                    <FormGroup>
                      <ButtonDropdown
                        isOpen={isOpen1}
                        toggle={this.toggleDropdown1}>
                        <DropdownToggle caret>
                          {this.state[`${resourceTypeParam}ToggleFilter`]}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem data-label="newest" onClick={this.changeValue}>
                            {NEWEST}
                          </DropdownItem>
                          <DropdownItem data-label="oldest" onClick={this.changeValue}>
                            {OLDEST}
                          </DropdownItem>
                          <DropdownItem data-label="ascending" onClick={this.changeValue}>
                            {SKILL_A_Z}
                          </DropdownItem>
                          <DropdownItem data-label="descending" onClick={this.changeValue}>
                            {SKILL_Z_A}
                          </DropdownItem>
                          <DropdownItem data-label="average_rating_desc" onClick={this.changeValue}>
                            {RATING_HIGH}
                          </DropdownItem>
                          <DropdownItem data-label="average_rating_asc" onClick={this.changeValue}>
                            {RATING_LOW}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              {
                isUndefined(this.props.params.userId) && resourceTypeParam === 'skill' && (
                  <Col>
                    <FormGroup className="addSkillButtonContainer">
                      <Button
                        onClick={this.toggleAddCustomSkill}
                        className="addSkillButton"
                        type="button">
                        {ADD_SKILLS}
                      </Button>
                    </FormGroup>
                  </Col>
                )
              }
            </Row>
            {isFilterRequesting ? <Loading/> : this.getContent(this.props[`${resourceTypeParam}List`])}
          </>)
          }
          {
            resourceTypeParam === 'skill' && this.state.addYourCustomSkills &&
            <AddYourCustomSkills
              isOpen={this.state.addYourCustomSkills}
              toggleModal={this.toggleAddCustomSkill}
              refreshMySkills={this.getMySkills}
              addedSkillsCount={skillsTotalItems || 0}/>
          }
        </div>
      </div>
    );
  }
}

export default withHooks(MySkills);
