import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

const whitePaper1 = 'https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-The-21st-Century-Career-Why-Perpetual-Career-Preparation-is-Necessary-to-Survive-and-Thrive-in-Todays-Economy.pdf';
const whitePaper2 = 'https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-Open-Badges-for-Empowerment_How-Open-Badges-Can-Be-Used-to-Recognize-and-Communicate-Hidden-Human-Capital.pdf';

const whitePapers = require('../../assets/images/img-whitepapers.png');
const whitePaper1_label = 'The 21st-Century Career';
const whitePaper2_label = 'Open Badges for Empowerment';

const talent = require('../../assets/images/img-talent.png');
const talentDev = require('../../assets/images/img-talentDev.png');
const talentEmp = require('../../assets/images/img-talentEmp.png');

class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="interior info about-page about">
        <Helmet title="About us"/>
        <div className="container">
          <div className="row">
            <div style={{paddingTop: 50,paddingBottom: 50}} className="content">
              <div className="info-title">
                <h1>CareerPrepped Connects the Key Stakeholders<br />in the Labor Market to Enable Skills-Based Hiring at Scale </h1>
              </div>
              <div className="content-description">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="subhead">Who are the key stakeholders in the labor market?</div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="talent-item">
                          <div className="icon"><img src={talent} height="80"/></div>
                          <div className="label"><strong>Talent</strong></div>
                          <p>CareerPrepped helps students, job seekers and working adults demonstrate their skills, get the job they want, and continually prepare for career success in a constantly changing job market.</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="talent-item">
                          <div className="icon"><img src={talentDev} height="80"/></div>
                          <div className="label"><strong>Talent Developers</strong></div>
                          <p>CareerPrepped helps career educators track learners' skill acquisition, rather than just course completion and grades, to supply top-quality talent to employers with proven skills.</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="talent-item">
                          <div className="icon"><img src={talentEmp} height="80"/></div>
                          <div className="label"><strong>Talent Employers </strong></div>
                          <p>CareerPrepped addresses the unmet needs of employers who want to recruit talent with verifiable skills - reducing employee recruitment, training, and turnover costs.</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="about-content">
                          <strong>CareerPrepped is Advancing Skills-First Approaches to Talent Development and Hiring</strong>
                          <p>According to <a href="https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/taking-a-skills-based-approach-to-building-the-future-workforce" target="_blank">McKinsey research</a>, validating skills is the main obstacle to skills-based hiring. To enable skills-based hiring at scale, verifiable skills must become the currency of the labor market and the focus of the entire talent supply chain. CareerPrepped actively engages educators and employers through a unique skills-based 360° feedback system to help learners and job seekers make their skills visible and verifiable. Equally important, CareerPrepped empowers users to develop and showcase their <a href="https://www.shrm.org/hr-today/news/hr-magazine/summer2021/pages/why-soft-skills-are-important.aspx" target="_blank">soft skills</a>, the essential workforce skills that are in demand by employers across all industries.</p>

                          <strong>CareerPrepped's Creator and Sponsor</strong>
                          <p>CareerPrepped is powered by <a href="https://www.maxknowledge.com/" target="_blank">MaxKnowledge, Inc.</a>, the leading online provider of talent development solutions for the Career and Technical Education (CTE) community. CareerPrepped is actively building a talent pipeline from the CTE sector because of the sector's focus on skill outcomes and workforce readiness.</p>

                          <strong>CareerPrepped is Proud to be Adopted and Endorsed by Key Partners</strong>
                          <p>The Association for Career and Technical Education (ACTE) has adopted CareerPrepped as <a href="https://www.youtube.com/watch?v=OzXtjgwFwiU" target="_blank">CTE's Virtual Community</a>, and the Accrediting Commission of Career Schools and Colleges (ACCSC) has adopted CareerPrepped to support its <a href="https://www.essentialworkforceskills.org/" target="_blank">Essential Workforce Skills Programmatic Certification</a>.</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <h2 style={{fontSize:18,backgroundColor:'#f1f1f1',padding:'10px 20px'}} className="text-center">CareerPrepped White Papers</h2>
                        <div className="mb-2 about-whitepaper">
                          <img src={whitePapers} className="img-fluid"/>
                          <div className="wp-links">
                            <a href={whitePaper1} target="_blank">{whitePaper1_label} </a>
                            <a href={whitePaper2} target="_blank">{whitePaper2_label} </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
