import React from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import {
  getEducators,
  getIndustryReps,
  getPeers,
  getMyBadges,
  getMySkills,
  getMySkillsPage,
  getRatings,
  getFollowers,
  clearMyBadges,
  clearMySkills,
} from '../../redux/actions/myskills';
import MySkills from './MySkills';
import Loading from '../../components/common/Loading';

const SkillsWrapper = (props) => {
  const params = useParams();

  return (
    <>
      {props.isRequestingProfile ?
        <Loading /> :
        <>
          <MySkills params={params} {...props} />
          <div className="clearfix" />
        </>}
    </>
  );
};

const mapStateToProps = (state) => {
  let skillList = [];
  let badgeList = [];
  const { profile } = state;
  if (state.myskills.data.skills && state.myskills.data.skills.length) {
    skillList = [...state.myskills.data.skills];
  }

  if (state.myskills.data.badges && state.myskills.data.badges.length) {
    badgeList = [...state.myskills.data.badges];
  }

  return {
    skillList,
    badgeList,
    skillsTotalPage: state.myskills.data.skillsTotalPage,
    badgesTotalPage: state.myskills.data.badgesTotalPage,
    skillsTotalItems: state.myskills.data.skillsTotalItems,
    badgesTotalItems: state.myskills.data.badgesTotalItems,
    badgeLinks: state.myskills.data.badgeLinks,
    currentUser: state.auth.data.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
    profile: profile.data,
    isRequestingProfile: profile.isRequesting
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getEducators,
      getIndustryReps,
      getPeers,
      getFollowers,
      getMyBadges,
      getMySkills,
      getMySkillsPage,
      getRatings,
      clearMySkills,
      clearMyBadges,
    },
    dispatch
  ),
});

SkillsWrapper.propTypes = {
  profile: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isRequestingProfile: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsWrapper);
