export default {
  /**
   * App components state
   */
  components: {
    forms: [],
    isMobile: false,
    sectionForms: [],
    updateResourceForms: [],
    onFocusForms: [],
    viewAvatarModal: {
      status: false,
      data: {},
    },
    modal: null,
    toggleComponents: []
  },

  /**
   * Router state
   */
  router: {
    storedAction: null,
    isHookedToARoute: {
      status: false,
      route: null,
    },
    pathname: null,
    nextPathname: null,
  },

  /**
   * Auth state
   */
  auth: {
    error: {},
    data: {
      token: null,
      currentUser: {},
    },
    lastLoggedInUserId: null,
    isRequesting: false,
    isAuthenticated: false,
  },

  /**
   * Reset password state
   */
  resetPassword: {
    error: {},
    data: {},
    isSubmitting: false,
  },

  /**
   * Profile state
   */
  profile: {
    error: {},
    data: {},
    isRequesting: false,
    isSubmitting: false,
    isUpdating: false,
    isUpdatingGroup: {
      status: false,
      groupId: null,
      isChildGroup: false,
    },

    unsavedChildGroupsData: {
      isSubmitting: false,
      institutionId: null,
      parentGroupId: null,
      parentGroupIndex: null,
    },
    deletingInterestId: null,
    /**
     * IR Configurations state
     */
    industryRepresentativeConfiguration: {
      frequencyInWeeks: 3,
      pauseFeedbackRequests: false,
      pauseStartDate: null,
      pauseEndDate: null,
      disableFeedbackRequests: false,
    },
  },

  /**
   * Contact us state
   */
  contact: {
    error: {},
    data: {},
    isSubmitting: false,
  },

  /**
   * Institutions state
   */
  institutions: {
    error: {},
    data: [],
    selectedInstitution: {},
    isRequesting: false,
  },

  /**
   * Groups state
   */
  groups: {
    error: {},
    data: [],
    page: 1,
    pageCount: 1,
    pageSize: 25,
    groupAdminsPage: 1,
    groupAdminsPageSize: 25,
    groupAdminsPageCount: 1,
    selectedGroup: {},
    selectedParentGroup: {},
    selectedGroupAdmins: [],
    filters: {
      searchQuery: '',
    },
    isChildGroupSelected: false,
    isAuthUserSelectedInstitutionAdmin: false,
    isSubmitting: {
      status: false,
      parentGroupId: null,
      duplicateGroups: [],
    },
    isRequesting: false,
    isInitialRequest: false,
    isRequestingGroupAdmins: false,
    isUpdating: { status: false, adminId: null },
  },

  /**
   * Educators state
   */
  educators: {
    data: [],
    error: {},
    groups: [],
    page: 1,
    pageCount: 1,
    pageSize: 10,
    statistics: {
      usersCount: 0,
      usersGroupsCount: 0,
      usersFilesCount: 0,
      usersClaimedBadges: 0,
      usersBadgeEndorsements: 0,
    },
    selectedGroup: {},
    isRequesting: false,
    isRequestingStats: false,
    isUpdating: false,
    isInitialRequest: false,
    isSubmitting: {
      status: false,
      duplicateGroups: [],
    },
  },

  /**
   * Industry Representatives state
   */
  industryRepresentatives: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * Peers state
   */
  peers: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * Users state
   */
  users: {
    error: {},
    data: [],
    page: 1,
    pageCount: 1,
    pageSize: 25,
    sort: {
      order: false, // False is descending, the default order from the API
      column: '',
      orderBy: 'firstName',
    },
    filters: {
      location: '',
      searchQuery: '',
      employmentType: [],
      employmentStatus: [],
    },
    displayAdvancedFilters: false,
    isRequesting: false,
    isFollowing: {
      status: false,
      index: null,
    },
  },

  /**
   * Admin Statistics state
   */
  statistics: {
    users: {
      error: {},
      data: {},
      isRequesting: false
    },
    jobs: {
      error: {},
      data: {},
      isRequesting: false
    },
    skills: {
      error: {},
      data: {},
      isRequesting: false
    },
    files: {
      error: {},
      data: {},
      isRequesting: false
    },
    badges: {
      error: {},
      data: [],
      isRequesting: false
    },
    educators: {
      error: {},
      data: [],
      selectedEducator: {},
      isRequesting: false,
      isInitialRequest: false,
      paginationData: {
        page: 1,
        page_count: 1,
        total_items: 0,
      }
    },
    groups: {
      error: {},
      data: [],
      selectedGroup: {},
      isRequesting: false,
      isInitialRequest: false,
      paginationData: {
        page: 1,
        page_count: 1,
        total_items: 0
      }
    },
    surveys: {
      error: {},
      data: [],
      isRequesting: false
    },
    surveyFeedbacks: {
      error: {},
      data: [],
      paginationData: {
        page: 1,
        page_count: 1,
        total_items: 0,
      },
      isRequesting: false
    }
  },

  /**
   * Group state
   */
  group: {
    error: {},
    data: [],
    myGroups: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: false,
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile education state
   */
  education: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, educationIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile experiences state
   */
  experiences: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, experienceIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile skills state
   */
  skills: {
    error: {},
    data: [],
    skillsUserId: null,
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, skillIndex: null, skillId: null },
    isDeleting: { status: false, id: null },
    skillBeingUpdated: {},
    unitSkill: {
      isRequesting: false,
      isUpdating: { status: false, id: null, index: null },
      isLikingFile: { status: false, id: null },
      data: {},
      error: {},
    },
    paginationData: {
      page: 1,
      page_count: null,
      total_items: 0,
    },
  },

  /**
   * Profile interests state.
   */
  interests: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, interestIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile certifications state
   */
  certifications: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, certificationIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile projects state
   */
  projects: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, projectIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile publications state
   */
  publications: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, publicationIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile awards state
   */
  awards: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, awardIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile associations state
   */
  associations: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, associationIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Profile clubs state
   */
  clubs: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, clubIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   *
   */
  connects: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: { status: false, profile: null },
    isUpdating: { status: false, connectIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Followers state
   */
  followers: {
    error: {},
    data: [],
    isRequesting: false,
    paginationData: {
      page: 1,
      page_count: null,
      total_items: 0,
    },
    isSubmitting: { status: false, id: null },
    isUpdating: { status: false, followerIndex: null },
    isDeleting: { status: false, id: [] },
  },

  /**
   * Following state
   */
  following: {
    error: {},
    data: [],
    isRequesting: false,
    paginationData: {
      page: 1,
      page_count: null,
      total_items: 0,
    },
    isSubmitting: { status: false, profile: null },
    isUpdating: { status: false, followingIndex: null },
    isDeleting: { status: false, id: [] },
  },

  /**
   * User registration state
   */
  registration: {
    data: {},
    error: {},
    status: false,
    isSubmitting: false,
    isRequesting: false,
    accessRequests: [],
    feedbacks: []
  },

  /**
   * Subscriptions state
   */
  subscriptions: {
    data: {},
    error: {},
    isSubmitting: false
  },

  /**
   * Units state
   */
  skillBuilders: {
    error: {},
    unit: {},
    data: [],
    isRequesting: false,
    isRequestingUnit: false,
    isSubmitting: false,
    isUpdating: { status: false, unitIndex: null },
    isDeleting: { status: false, id: null },
    isSubmittingBadge: { status: false, id: null },
    isUpdatingBadge: { status: false, id: null },
    isDeletingBadge: { status: false, id: null },
  },

  /**
   * Reflections state
   */
  reflections: {
    error: {},
    data: [],
    headers: {},
    isRequesting: false,
    isSubmitting: false,
    sharing: {
      isSharing: false,
      successfullyShared: false,
    },
    isUpdating: false,
    isDeleting: { status: false, id: null },
    isDownloading: { status: false, data: '' },
    deleteId: '',
  },

  /**
   * Unit sections state
   */
  sections: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, sectionIndex: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Unit discussions state
   */
  discussions: {
    error: {},
    data: [],
    commentCount: {}, // actual comment count for a post.
    discussionShare: {},
    isRequesting: false,
    isSubmitting: false,
    isSubmittingLike: { status: false, discussionId: null, userId: null },
    isUpdating: { status: false, discussionId: null },
    isDeleting: { status: false, id: null },
    paginationData: {
      page: 1,
      page_count: null,
    },
  },

  /**
   * Unit discussions comments state
   */
  comments: {
    error: {},
    replyData: [],
    loadedComments: {},
    isRequesting: { status: false, discussionId: null },
    isSubmitting: { status: false, discussionId: null },
    isSubmittingLike: {
      status: false,
      commentId: null,
      discussionId: null,
      userId: null,
    },
    isUpdating: { status: false, discussionId: null },
    isDeleting: { status: false, id: null, discussionId: null },
    paginationData: {
      page: 1,
      page_count: null,
    },
  },

  /**
   * Wall posts state
   */
  wallPosts: {
    error: {},
    data: [],
    post: {},
    commentCount: {}, // actual comment count for a post.
    wallShare: {},
    isRequesting: false,
    isSubmitting: false,
    isSubmittingLike: {
      status: false,
      userId: null,
      wallPostIndex: null,
    },
    isUpdating: { status: false, id: null },
    isDeleting: { status: false, id: null, wallPostId: null },
    paginationData: {
      page: 1,
      page_count: null,
    },
  },

  /**
   * Wall posts comments state
   */
  wallPostComments: {
    error: {},
    data: [],
    replyData: [],
    loadedComments: {},
    isRequesting: { status: false, discussionId: null },
    isSubmitting: { status: false, wallPostId: null },
    isSubmittingLike: {
      status: false,
      userId: null,
      wallPostCommentIndex: null,
    },
    isUpdating: { status: false, wallPostId: null },
    isDeleting: { status: false, id: null },
    paginationData: {
      page: 1,
      page_count: null,
    },
  },

  /**
   * Notifications state
   */
  notifications: {
    error: {},
    data: [],
    isRequesting: false,
    isUpdating: { status: false, notificationId: null },
    isDeleting: { status: false, notificationId: null },
    unreadCount: 0,
    selectedNotificationId: null,
    isNotificationDropdownOpen: false,
  },

  /**
   * Messages state
   */
  messages: {
    error: {},
    data: [],
    query: '',
    isRequesting: false,
    isSubmitting: false,
    isSearching: false,
    isDeleting: { status: false, messageId: null },
    isUpdating: { status: false, messageId: null },
    isReplying: { status: false, messageId: null },
    paginationData: {
      page: 1,
      page_count: null,
      total_items: 0,
    },
  },

  /**
   * Messages state
   */
  messageReplies: {
    error: {},
    replies: [],
    thread: {
      isRequestingThread: false,
      thread: {},
      error: {},
    },
    isRequesting: false,
    isSubmitting: false,
    paginationData: {
      page: 1,
      page_count: null,
    },
  },

  /**
   * Job search state
   */
  jobSearch: {
    data: {},
    queryParams: {
      query: '',
      location: '',
      country: {
        value: '1eb2d271-1f99-11ec-a80e-0242ac120003',
        label: 'United States',
        code: 'us'
      },
      state: '',
      city: '',
      jobType: '',
      radius: { value: 40, label: '25 Miles' },
      industry: '',
      sort: { value: 'relevance', label: 'Relevance' },
      start: 0,
      days: 7,
    },
    isRequesting: false,
    isSubmitting: { status: false, id: null },
    displayAdvancedFilter: false,
  },

  /**
   * Job matches state
   */
  jobMatches: {
    data: [],
    cities: [],
    error: {},
    selected: [],
    paginationData: {
      page: 1,
      pageCount: null,
      pageSize: 50,
      totalItems: 0,
      hasMoreResults: false,
      matchSize: 0,
      searchAfter: []
    },
    citiesPaginationData: {
      page: 1,
      pageCount: null,
      pageSize: 26,
      totalItems: 0
    },
    queryParams: {
      query: '',
      state: '',
      city: '',
      jobType: '',
      start: 0,
      remoteJobs: false,
      radius: { value: 40, label: '25 Miles' },
      sort: { value: '_score', label: 'Relevance' }
    },
    filters: {
      jobLocation: [],
      jobType: null,
      jobAdvertiserType: null
    },
    isRequesting: false,
    isSubmitting: false,
    allDuplicatesSubmitted: false,
    isRequestingCities: false
  },

  /**
   * Job boards state
   */
  jobBoards: {
    data: [],
    paginationData: {
      page: 1,
      page_count: null,
    },
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, id: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Job boards state
   */
  jobNotes: {
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, id: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Job match settings state
   */
  jobMatchSettings: {
    data: {},
    error: {},
    isSubmitting: false,
    isRequesting: false,
    isUpdating: false
  },

  /**
   * Files state
   */
  files: {
    error: {},
    data: [],
    skillbuilderFiles: [],
    isRequesting: false,
    isUploading: { status: false, recentId: null },
    isDeleting: { status: false, id: null },
    isLiking: { status: false, id: null },
    isUpdating: false,
    fileData: {
      page: 1,
      page_count: null,
    },
    selectedPortfolio: {},
    unitFile: {
      isRequesting: false,
      data: {},
      error: {},
      isLiking: { status: false, id: null },
    },
    unitFileComments: {
      isSubmitting: { status: false, fileId: null, commentId: null },
      isUpdating: { status: false, commentId: null, replyId: null },
      isDeleting: { status: false, id: null, replyId: null },
      isLiking: { status: false, id: null, replyId: null },
      isRequesting: false,
      comments: [],
      error: {},
    },
  },

  /**
   * All badges state
   */
  allBadges: {
    error: {},
    data: [],
    paginationData: {
      page: 1,
      page_count: 1,
      total_items: 0,
    },
    isRequesting: false,
    isSubmitting: false,
    displayAdvancedFilter: false,
  },

  /**
   * Badges state
   */
  badges: {
    error: {},
    data: [],
    emptySearchResult: false,
    selectedBadges: [],
    paginationData: {
      page: 1,
      page_count: 1,
      total_items: 0,
    },
    resourceData: {},
    isRequesting: false,
    isSubmitting: false,
    isUpdatingId: null,
    isRemovingFileId: null,
    isIssuingBadge: { status: false, badgeIndex: null },
    isDeleting: { status: false, id: null },
    issuedBadges: {
      error: {},
      data: [],
      paginationData: {
        page: 1,
        page_count: 1,
        total_items: 0,
      },
      isRequesting: false,
    },
  },

  /**
   * Badges assertion state
   */
  badgeAssertion: {
    error: {},
    data: {},
    isRequesting: false,
    isSubmitting: false,
    isUpdating: false,
    isUpdatingJustificationAnswer: { index: null, status: false },
    isLikingFile: { status: false, id: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Badges assertion state
   */
  badgeEndorsement: {
    error: {},
    selectedBadges: [],
    data: {},
    isRequesting: false,
    isSubmitting: false,
    requestedEndorsements: [],
  },

  /**
   * Badges assertion state
   */
  resume: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isDeleting: { status: false, id: null },
  },
  /**
   * Webpage state
   */
  webpage: {
    error: {},
    data: {},
    isRequesting: false
  },

  /**
   * Organization state
   */
  organizations: {
    error: {},
    data: [],
    filters: {
      searchQuery: '',
    },
    isRequesting: false,
    isSubmitting: false,
    isInitialRequest: false,
    paginationData: {
      page: 1,
      page_count: 1,
      total_items: 0,
    },
    selectedOrganization: {},
    isUpdating: { status: false, id: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Organization invitation state
   */
  organizationInvitations: {
    error: {},
    data: {},
    invites: [],
    filters: {
      searchQuery: '',
    },
    page: 1,
    page_count: 1,
    page_size: 25,
    total_items: 0,
    inviteDetails: {},
    isRequesting: false,
    isSubmitting: false,
    isRevoking: { status: false, id: null },
    isResending: { status: false, id: null },
  },

  /**
   * Organization Users state
   */
  organizationUsers: {
    page: 1,
    pageCount: 1,
    error: {},
    data: [],
    groupUsers: [],
    isFollowing: {
      status: false,
      index: null,
    },
    filters: {
      usersLocation: '',
      usersSearchQuery: '',
      usersEmploymentType: [],
      usersEmploymentStatus: [],

      invitesSearchQuery: '',
    },
    isRequesting: false,
  },

  /**
   * Organization Stats
   */
  organizationStats: {
    selectedInstitionIndex: 0,
    numberOfGroupsToCreate: 0,
    parentGroupId: null,
    error: {},
    data: {},
    isRequesting: false,
    isUpdatingAdmins: { status: false, adminId: null },
  },

  /**
   * Questionaires state
   */
  questionaires: {
    error: {},
    data: [],
    isRequesting: false,
  },

  /**
   * SB category state
   */
  category: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, id: null },
  },

  /**
   * SB category state
   */
  networks: {
    error: {},
    data: [],
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, id: null },
  },

  /**
   * Interview prep video state
   */
  interview: {
    error: {},
    data: [],
    isRequesting: false,
    unitVideo: {
      isRequestingDetails: false,
      videoDetails: {},
      error: {},
      isLiking: { status: false, id: null },
    },
    unitVideoComments: {
      isSubmitting: { status: false, videoId: null, commentId: null },
      isUpdating: { status: false, commentId: null, replyId: null },
      isDeleting: { status: false, id: null, replyId: null },
      isLiking: { status: false, id: null, replyId: null },
      isRequesting: false,
      comments: [],
      commentCount: 0,
      error: {},
    },
  },

  /**
   * CP search state
   */
  search: {
    error: {},
    data: {
      people: [],
      badges: [],
      files: [],
      posts: [],
    },
    paginationData: {
      page: 1,
      page_count: 1,
      total_items: 0,
    },
    type: null,
    query: null,
    isRequesting: false,
    isFollowing: { status: false, userId: null },
    isLikingFile: { status: false, fileId: null },
    isLiking: { status: false, wallPostIndex: null },
    isLikingPostComment: { status: false, wallPostId: null, index: null },
    isSubmitting: { status: false, wallPostId: null },
    isUpdating: { status: false, wallPostId: null },
    isDeletingPostComment: { status: false, id: null, wallPostId: null },
    isRequestingComments: { status: false, wallPostIndex: null },
    resultPostItemShare: {},
  },

  /**
   * Subscription plan
   */
  subscription: {
    error: {},
    data: {},
    isSubmitting: false,
  },

  /**
   * Admin group state
   */
  adminGroups: {
    error: {},
    data: {},
    selectedChildGroup: {},
    institutionGroups: {
      data: [],
      isRequesting: false,
      error: {},
    },
    isRequesting: false,
    isSubmitting: false,
    isUpdating: { status: false, id: null },
    isDeleting: { status: false, id: null },
  },

  /**
   * Wall posts state
   */
  wallPostsNormalized: {
    error: {},
    data: {
      byId: {},
      allIds: [],
    },
    commentCount: {}, // actual comment count for a post.
    wallShare: {},
    isRequesting: false,
    isSubmitting: false,
    isSubmittingLike: { status: false, wallPostIndex: null },
    isUpdating: { status: false, id: null },
    isDeleting: { status: false, id: null, wallPostId: null },
    paginationData: {
      page: 1,
      page_count: null,
    },
  },
  myskills: {
    error: {},
    data: {
      educators: [],
      industryReps: [],
      peers: [],
      followers: [],
      badges: [],
      skills: [],
      ratings: [],
      defaultSkills: [],
      reviewsBreakupData: {},
    },
    paginationData: {
      page: 1,
      pageCount: 1,
    },
    defaultSkillsPaginationData: {
      page: 1,
      pageCount: 1
    },
    skillsUserId: null,
    isRequesting: false,
    isUpdating: { status: false, index: null },
    isDeleting: { status: false, id: null }
  },

  /**
   * Skill categories state
   */
  skillCategories: {
    categories: [],
    subCategories: [],
    error: {},
    categoriesPage: 1,
    subCategoriesPage: 1,
    categoriesPageCount: 1,
    subCategoriesPageCount: 1,
    categoriesPageSize: 10,
    subCategoriesPageSize: 10,
    isRequestingCategories: false,
    isRequestingSubCategories: false
  },

  /**
   * Profile skills state
   */
  testimonials: {
    error: {},
    data: [],
    detail: {},
    page: 1,
    page_size: 25,
    page_count: 1,
    total_items: 100,
    isRequesting: false,
    isSubmitting: false,
  },

  clusterIndustryOccupation: {
    error: {},
    data: {
      clusters: [],
      industries: [],
      occupations: [],
      searchList: [],
      careerInterest: [],
      rowCounts: [],
    },
    isRequesting: false,
  },

  /**
   * Countries state
   */
  countries: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * Country States state
   */
  states: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * State Cities state
   */
  cities: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * Clusters state
   */
  clusters: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * Industries state
   */
  industries: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  /**
   * Occupations state
   */
  occupations: {
    data: [],
    error: {},
    page: 1,
    pageCount: 1,
    pageSize: 10,
    isRequesting: false
  },

  feedbackList: {
    error: {},
    data: {
      requested: [],
      received: [],
      completedFeedback: [],
      pendingFeedback: [],
      resourceFeedback: [],
    },
    isRequesting: false,
  },

  feedbackQuestions: {
    error: {},
    data: {
      feedbackQuestions: {
        likert: [],
        comment: {},
        multichoice: [],
        likertQuestionText: '',
        multiChoiceQuestionText: '',
        likeQuestionId: '',
        multiChoiceQuestionId: '',
        commentQuestionId: '',
      },
      portfolioQuestions: {
        likert: [],
        comment: {},
        multichoice: [],
        likertQuestionText: '',
        multiChoiceQuestionText: '',
        likeQuestionId: '',
        multiChoiceQuestionId: '',
        commentQuestionId: '',
      },
      feedbackData: {},
      postData: {},
      getFeedbackUserDetail: {},
    },
    getFeedbackUserDetail: {},
    isRequesting: false,
    isSubmitting: 'request',
  },
  invite: {
    error: {},
    data: [],
    isRequesting: false,
  },

  feedbackOptions: {
    error: {},
    data: {},
    isRequesting: false,
  },

  myCareerPreppedStatus: {
    error: {},
    data: {},
    isRequesting: false,
  },

  irs: {
    manageIrs: []
  }
};
