import React from 'react';

import PropTypes from 'prop-types';
import {
  Row,
  Col,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';

const UsersTableStatusCell = (props) => {
  const { user } = props;
  const completedActions = user.userStatus.completedCards.length;
  const cpStatusTitleAlias = {
    'skills': 'Add 10 Skills',
    'education': 'Add Education',
    'experience': 'Add Experience',
    'summary': 'Add About Me',
    'location': 'Add Location',
    'worksituation': 'Add Work Situation',
    'headline': 'Add Headline',
    'avatar': 'Add Photo',
    'contact': 'Add Contact Info'
  };

  return (
    <div>
      {((completedActions + user.userStatus.resumeAction) === 10) ? (
        <span
          className="font14 font-weight-bold"
          style={{ textAlign: 'center' }} >
          <div className="text-center">
            <div id={`statusStage${user.id}`} className="orange pointer">
              Launching
            </div>
          </div>
        </span>
      ) : completedActions >= 4 && completedActions < 10 ? (
        <span
          className="font14 font-weight-bold"
          style={{ textAlign: 'center' }} >
          <div className="text-center">
            <div id={`statusStage${user.id}`} className="orange pointer">
              Developing
            </div>
          </div>
        </span>
      ) : (
        <span
          className="font14 font-weight-bold"
          style={{ textAlign: 'center' }} >
          <div className="text-center">
            <div id={`statusStage${user.id}`} className="orange pointer">
              Beginning
            </div>
          </div>
          {props.statusClicked[user.id]}
        </span>
      )}

      <UncontrolledPopover
        placement="bottom"
        target={`statusStage${user.id}`}
        className="careerstatus-popover"
        trigger="legacy">
        <PopoverBody>
          <b>Actions Needed</b>
          {user.userStatus &&
            user.userStatus?.completedCards?.map((action) => {
              return (
                <Row key={action.id}>
                  <Col xs="12">
                    <s><span className="text-muted">{cpStatusTitleAlias[action.statusType]}</span></s>
                  </Col>
                </Row>
              );
            }
            )}

          {user.userStatus &&
            user.userStatus?.pendingCards?.map((action) => {
              return (
                <Row key={action.id}>
                  <Col xs="12">
                    <span>{cpStatusTitleAlias[action.statusType]}</span>
                  </Col>
                </Row>
              );
            }
            )}

          <Row key="create-resume">
            <Col xs="12">
              {user.userStatus.resumeAction ?
                <s><span className="text-muted">Create Resume</span></s> :
                <span>Create Resume</span>}
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>

    </div>
  );
};

UsersTableStatusCell.propTypes = {
  user: PropTypes.object,
  statusClicked: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default UsersTableStatusCell;
