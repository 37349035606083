import { CALL_API } from '../../middlewares/api';

import {
  RESUME_REQUEST,
  RESUME_SUCCESS,
  RESUME_FAILURE,
  CREATE_RESUME_REQUEST,
  CREATE_RESUME_SUCCESS,
  CREATE_RESUME_FAILURE,
  DELETE_RESUME_REQUEST,
  DELETE_RESUME_SUCCESS,
  DELETE_RESUME_FAILURE,
} from '../../constants';

export const resumeRequest = (userId = null) => {
  let endpoint = 'resume/resume';

  if (userId)
    endpoint = `${endpoint}?userId=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        RESUME_REQUEST,
        RESUME_SUCCESS,
        RESUME_FAILURE
      ]
    },
  };
};

export const createResume = (data) => {
  const endpoint = 'resume/resume';

  return {
    [CALL_API]: {
      endpoint,
      data,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_RESUME_REQUEST,
        CREATE_RESUME_SUCCESS,
        CREATE_RESUME_FAILURE,
      ],
    },
  };
};

export const deleteResume = (id) => {
  const endpoint = `resume/resume/${id}`;

  return {
    [CALL_API]: {
      endpoint,
      id,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_RESUME_REQUEST,
        DELETE_RESUME_SUCCESS,
        DELETE_RESUME_FAILURE,
      ],
    },
  };
};
