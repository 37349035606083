import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

const UsersTableNameCell = ({ user, nameOrder, currentUserId }) => {
  const isCurrentUser = (currentUserId === user.id);

  return (
    <div className="user-info">
      <div className="media align-items-center">
        <div className="media-left mr-3">
          <Link
            title={`Link to ${user.name}’s Career Site`}
            target={'_blank'}
            to={`/cp/${user.vanity}`}>
            {
              user.avatar === '' ?
                <span
                  className="media-object profile-image medium initial-avatar">
                  {user.name.slice(0,1)}
                </span> :
                <span>
                  <img className="media-object avatar" src={user.avatar} alt="User avatar"/>
                </span>
            }
          </Link>
        </div>
        <div className="media-body" style={{textAlign: 'left'}}>
          <h5 className="media-heading mt-0 mb-0">
            <Link
              className="user-name"
              title={`Link to ${user.name}’s Career Site`}
              target="_blank"
              to={`/cp/${user.vanity}`}>
              {nameOrder === 'lastName' ? `${user.lastName} ${user.firstName}` : user.name}
            </Link>{' '}

            {isCurrentUser &&
              <span className="badge badge-pill badge-success">It's you</span>}
          </h5>
          {user.email}

          {user.isGroupAdmin &&
            <span
              className="badge badge-pill badge-default">
              Group Admin
            </span>}

          {user.isInstitutionAdmin &&
            <span
              className="badge badge-pill badge-default">
              Organization Admin
            </span>}
        </div>
      </div>
    </div>
  );
};

UsersTableNameCell.propTypes = {
  user: PropTypes.object,
  nameOrder: PropTypes.string,
  currentUserId: PropTypes.string
};

const mapStateToProps = state => ({
  currentUserId: state.auth.data.currentUser.id
});

export default connect(mapStateToProps)(UsersTableNameCell);
