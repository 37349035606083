import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { isNull } from 'lodash';

import { withHooks } from '../../utils/withHooks';

const notFoundIcon = require('../../assets/images/404.png');

class NotFound extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired
  };

  render(){
    const { isAuthenticated, location } = this.props;
    const inactiveSubscription = location.pathname === '/restart-subscription';
    const subscriptionType = !isNull(location.state) ? location.state.subscriptionType : null;
    const headerText = (inactiveSubscription && (subscriptionType === 'individualUser')) ?
      'Subscription Update Needed' :
      (inactiveSubscription && (subscriptionType === 'organizationUser')) ?
        'Access to the Admin Panel is currently unavailable.' :
        'Whoops...Looks like you\'re lost!';

    return (
      <div id="section1" className={classNames('section', { info: !isAuthenticated })}>
        <div className="container">
          <div className="flex-center text-center mt-3">
            {!inactiveSubscription &&
              <img
                className="img-fluid mt-3"
                src={notFoundIcon}
                width="500"
                alt="404 - Not Found"/>}

            <h2 className="mt-2 mb-2">{headerText}</h2>
            {(inactiveSubscription && (subscriptionType === 'individualUser')) &&
              <p>It looks like your subscription has expired, or your payment information needs an update. To continue accessing CareerPrepped, please update your payment details or renew your subscription. Once complete, your access will be fully restored.</p>}

            {(inactiveSubscription && (subscriptionType === 'organizationUser')) &&
              <><p>It looks like the subscription for this account has ended or there’s an issue with payment. To regain access, please ensure the subscription is renewed or payment details are updated.</p><p>If you're not the Primary Admin, please contact them for more information.</p></>}

            {(inactiveSubscription && (subscriptionType === 'individualUser')) &&
              <a
                target="_blank"
                rel="noopener"
                href="https://billing.stripe.com/p/login/test_28obKT0478ib2QgeUU"
                className="btn btn-lg btn-primary">Update Subscription Information</a>}

            {(!inactiveSubscription) &&
              <Link
                className="btn btn-lg btn-primary"
                to={isAuthenticated ? '/home' :'/'}>
                Let's take you back home!
              </Link>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });

export default connect(mapStatetoProps)(withHooks(NotFound));
